@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .h1 {
        @apply text-5xl font-extrabold
    }

    .h2 {
        @apply text-4xl font-bold
    }

    .h3 {
        @apply text-3xl font-bold
    }

    .h4 {
        @apply text-2xl font-bold
    }

    .h5 {
        @apply text-xl font-bold
    }

    .container {
        @apply bg-white p-4 border-gray-100 border-[1px] rounded-sm shadow-sm;
    }

    .headerElement {
        @apply flex basis-full justify-between mb-5;
    }

    .headline {
        @apply text-black text-xl text-left font-semibold;
    }

    .description {
        @apply mt-2 text-gray-500 text-left;
    }

    .btn-sq {
        @apply w-11 h-11 m-1 p-2 bg-secondary rounded-md text-center
        text-black cursor-pointer hover:bg-primary hover:text-white;
    }

    .btn-focus {
        @apply bg-primary text-white;
    }
}